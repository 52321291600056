<template>
  <div>
    <i-top-banner :datas="topBanner" :isContentBanner="isContentBanner"></i-top-banner>
    <i-content-banner :datas="contentBanner" v-if="!isContentBanner"></i-content-banner>
    <img :src="contentImg" style="width:100%" alt="">

    <i-share :datas="shareData"></i-share>
  </div>
</template>
<script>
import request from '@/axios'
import iTopBanner from "@/components/Game/topBanner"
import iShare from "@/components/Game/share"
import iContentBanner from "@/components/Game/contentBanner/ft"
export default {
  data() {
    return {
      topBanner: {},
      contentImg: "",
      shareData: {},
      contentBanner: "",
      isContentBanner: false,
    }
  },
  components: {
    iTopBanner,
    iShare,
    iContentBanner
  },
  watch:{
    langType(){
      this.init();
    },
    $route(n,o){
      // alert(n)
      this.init();
    }
  },
  computed: {
    ...Vuex.mapGetters([
      "langType",
    ]),
  },
  created(){
    this.init();
    
  },
  methods: {
    init(){
      let id = this.$route.query.id;
      request({
        url: "./config/games/"+id+".json",
        method: 'get',
        type: "file",
      }).then(res=>{
        if(res.isContentBanner){
          this.isContentBanner = true;
        }else{
          this.isContentBanner = false;
        }
        this.$set(this,"topBanner",Object.assign(res[this.langType],res.banner,{contentBannerImg:res[this.langType].contentBanner["img"]}));
        this.$set(this,"contentBanner",Object.assign(res[this.langType].contentBanner,{contentImg:res[this.langType].contentImg}));
        this.contentImg = res[this.langType].contentImg;
        this.$set(this,"shareData",res[this.langType].bottom);
      }).catch(e=>{
      })
    }
  }
}
</script>